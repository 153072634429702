<section class="newsroom-header" *ngIf="newsroomConfig" [style.background-image]="'url(' + newsroomConfig?.image + ')'">
  <div class="text-container">
    <h1 class="title"> {{ newsroomConfig?.title }} </h1>
    <div class="content"> {{ newsroomConfig?.subtitle }} </div>
  </div>
</section>

<section class="newsroom-filter">
  <div class="row">
    <div class="col-12 col-md-5 col-lg-4 mb-1">
      <button type="button" class="btn filter-btn" (mouseover)="contentTypeFilter=true">
        {{ newsroomConfig?.label_content_type }}
        <i class="filter-arrow"></i>
        <ul class="filter-list" *ngIf="contentTypeFilter">
          <li (click)="filterByContentType('all')">
            {{ newsroomConfig?.label_all }}
          </li>
          <li [class.checked]="contentType.includes('news')" (click)="filterByContentType('news')">
            {{ newsroomConfig?.label_content_type_news }}
            <img *ngIf="contentType.includes('news')" src="../assets/img/icon/done-validation.svg" alt="" />
          </li>
          <li [class.checked]="contentType.includes('blog')"  (click)="filterByContentType('blog')">
            {{ newsroomConfig?.label_content_type_blog }}
            <img *ngIf="contentType.includes('blog')" src="../assets/img/icon/done-validation.svg" alt="" />
          </li>
          <li [class.checked]="contentType.includes('leadership_paper')"  (click)="filterByContentType('leadership_paper')">
            {{ newsroomConfig?.label_content_type_leadership_paper }}
            <img *ngIf="contentType.includes('leadership_paper')" src="../assets/img/icon/done-validation.svg" alt="" />
          </li><!-- Social feeds filter link
          <li [class.checked]="contentType.includes('social_post')"  (click)="filterByContentType('social_post')">
            {{ newsroomConfig?.label_content_type_social_feed }}
            <img *ngIf="contentType.includes('social_post')" src="../assets/img/icon/done-validation.svg" alt="" />
          </li>-->
        </ul>
      </button>
    </div>
    <div class="col-12 col-md-4 col-lg-4 text-center mb-1">
      <button type="button" class="btn filter-btn" (mouseover)="tagFilter=true">
        {{ newsroomConfig?.label_theme }}
        <i class="filter-arrow"></i>
        <ul class="filter-list" *ngIf="tagFilter">
          <li (click)="filterByTag('all')">
            {{ newsroomConfig?.label_all }}
          </li>
          <li [class.checked]="tag.includes(theme)" *ngFor="let theme of newsroomConfig?.themes" (click)="filterByTag(theme)">
            {{ theme }}
            <img *ngIf="tag.includes(theme)" src="../assets/img/icon/done-validation.svg" alt="" />
          </li>
        </ul>
      </button>
    </div>
    <div class="col-12 col-md-4 col-lg-4 mb-1">
      <button type="button" class="btn filter-btn pull-right" (mouseover)="dateFilter=true">
        {{ newsroomConfig?.label_date }}
        <i class="filter-arrow"></i>
        <ul class="filter-list" *ngIf="dateFilter">
          <li (click)="filterByDate('all')">
            {{ newsroomConfig?.label_all }}
          </li>
          <li [class.checked]="dateString == 'week'" (click)="filterByDate('week')">
            {{ newsroomConfig?.label_date_last_week }}
            <img *ngIf="dateString == 'week'" src="../assets/img/icon/done-validation.svg" alt="" />
          </li>
          <li [class.checked]="dateString == 'month'" (click)="filterByDate('month')">
            {{ newsroomConfig?.label_date_last_month}}
            <img *ngIf="dateString == 'month'" src="../assets/img/icon/done-validation.svg" alt="" />
          </li>
          <li [class.checked]="dateString == 'quarter'" (click)="filterByDate('quarter')">
            {{ newsroomConfig?.label_date_last_quarter }}
            <img *ngIf="dateString == 'quarter'" src="../assets/img/icon/done-validation.svg" alt="" />
          </li>
          <li [class.checked]="dateString == 'semester'" (click)="filterByDate('semester')">
            {{ newsroomConfig?.label_date_last_semester }}
            <img *ngIf="dateString == 'semester'" src="../assets/img/icon/done-validation.svg" alt="" />
          </li>
          <li [class.checked]="dateString == 'year'" (click)="filterByDate('year')">
            {{ newsroomConfig?.label_date_last_year }}
            <img *ngIf="dateString == 'year'" src="../assets/img/icon/done-validation.svg" alt="" />
          </li>
        </ul>
      </button>
    </div>
  </div>
</section>

<section class="newsroom-list">
  <div class="row">
    <div *ngFor="let news of newsroomList?.news; let index = index"
         [className]="news.hot_news == 1 ? 'col-lg-6 col-12 col-sm-12 col-md-8 mb-4' : 'mb-4 col-lg-3 col-12 col-sm-6 col-md-4'">
      <div [className]="news.hot_news == 1 ? 'card testimonial' : 'card'" (click)="goToDetails(news)">
        <div class="card-top">
          <img class="card-top-img" src="{{ news.image.url }}" alt="{{ news.image.alt }}" title="{{ news.image.title }}">
          <span class="card-top-date text-center">
            <i>{{ formatDate(news.date, 'day') }}</i> {{ formatDate(news.date, 'month') }}
          </span>
          <p class="card-top-tags" [ngSwitch]="news.type">
            <span *ngSwitchCase="'news'">{{ news.type + ', '}} {{ news.tags }}</span>
            <span *ngSwitchCase="'blog'">{{ news.type + ', '}} {{ news.tags }}</span>
            <span *ngSwitchCase="'social_post'">{{ news.tags }}</span>
            <span *ngSwitchCase="'leadership_paper'">{{ newsroomConfig?.label_content_type_leadership_paper }}</span>
          </p>
        </div>
        <div class="card-body">
          <h2 class="card-title" *ngIf="news.type != 'social_post'">
            {{ ( news.title.length > 58 ) ? ( news.title | slice:0:56 ) + '...' : (news.title) }}
          </h2>
          <p class="card-text">
            {{ ( news.summary.length > 110 ) ? ( news.summary | slice:0:110 ) + '...' : (news.summary) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>


<section class="newsroom-load-more" *ngIf="showLoadMore">
  <div class="row">
    <div class="col col-md-12 text-center">
      <button type="button" class="amc-btn load-more" (click)="loadMoreNews()">
        {{ newsroomConfig?.load_more }}
      </button>
    </div>
  </div>
</section>
