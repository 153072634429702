import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { YouTubePlayerModule } from "@angular/youtube-player";
import { AppRoutingModule } from './app-routing/app-routing.module';
import { RecaptchaModule } from "ng-recaptcha";
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FormsModule } from "@angular/forms";

import { TruncatePipe } from './pipes/truncatePipe';
import { SafeHtmlPipe } from "./pipes/safeHtml";
import { StripHtmlPipe } from "./pipes/stripHtml";

import { AppComponent } from './app.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { HeaderComponent } from './components/header/header.component';
import { LeaderBoardComponent } from './components/homepage/leader-board/leader-board.component';
import { FooterComponent } from './components/footer/footer.component';
import { PersonaeComponent } from './components/homepage/personae/personae.component';
import { FootprintComponent } from './components/homepage/footprint/footprint.component';
import { SocialMediaComponent } from './components/homepage/social-media/social-media.component';
import { SustainabilityComponent } from './components/homepage/sustainability/sustainability.component';
import { ProjectsComponent } from './components/homepage/projects/projects.component';
import { NodeComponent } from './components/node/node.component';
import { HrComponent } from './components/homepage/hr/hr.component';
import { NewsroomComponent } from './components/newsroom/newsroom.component';
import { DetailsComponent } from './components/newsroom/details/details.component';
import { GenericComponent } from './components/generic/generic.component';
import { ScrollToTopComponent } from './components/common/scroll-to-top/scroll-to-top.component';
import { FreeTextComponent } from './components/generic/free-text/free-text.component';
import { QuoteComponent } from './components/generic/quote/quote.component';
import { BgcolorTextComponent } from './components/generic/bgcolor-text/bgcolor-text.component';
import { RelatedNewsComponent } from './components/generic/related-news/related-news.component';
import { VideoComponent } from './components/generic/video/video.component';
import { CtaComponent } from './components/generic/cta/cta.component';
import { FactsComponent } from './components/generic/facts/facts.component';
import { SliderComponent } from './components/generic/slider/slider.component';
import { ReferencesComponent } from './components/references/references.component';
import { ReferenceComponent } from './components/references/reference/reference.component';
import { SocialShareComponent } from './components/common/social-share/social-share.component';
import { ContactComponent } from './components/contact/contact.component';
import { FormsComponent } from './components/forms/forms.component';
import { NewsletterTagsFormComponent } from './components/newsletter-tags-form/newsletter-tags-form.component';
import { NewsletterUnsubscriptionFormComponent } from './components/newsletter-unsubscription-form/newsletter-unsubscription-form.component';
import { NewsletterUpdateFormComponent } from './components/newsletter-update-form/newsletter-update-form.component';
import { FaqComponent } from './components/generic/faq/faq.component';
import { SearchComponent } from './components/search/search.component';
import { SitemapComponent } from './components/sitemap/sitemap.component';
import { CookiesComponent } from './components/common/cookies/cookies.component';
import { SpinnerComponent } from './components/common/spinner/spinner.component';
import { languageGuard } from './guards/language.guard';

@NgModule({
  declarations: [
    /** pipes */
    TruncatePipe,
    SafeHtmlPipe,
    StripHtmlPipe,

    /** components */
    AppComponent,
    HomepageComponent,
    HeaderComponent,
    LeaderBoardComponent,
    FooterComponent,
    PersonaeComponent,
    FootprintComponent,
    SocialMediaComponent,
    SustainabilityComponent,
    ProjectsComponent,
    NodeComponent,
    HrComponent,
    NewsroomComponent,
    DetailsComponent,
    GenericComponent,
    ScrollToTopComponent,
    FreeTextComponent,
    QuoteComponent,
    BgcolorTextComponent,
    RelatedNewsComponent,
    VideoComponent,
    CtaComponent,
    FactsComponent,
    SliderComponent,
    ReferencesComponent,
    ReferenceComponent,
    SocialShareComponent,
    ContactComponent,
    FormsComponent,
    NewsletterTagsFormComponent,
    NewsletterUnsubscriptionFormComponent,
    NewsletterUpdateFormComponent,
    FaqComponent,
    SearchComponent,
    SitemapComponent,
    CookiesComponent,
    SpinnerComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SlickCarouselModule,
    YouTubePlayerModule,
    FormsModule,
    RecaptchaModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
