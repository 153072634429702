import { Component, OnInit, OnDestroy } from '@angular/core';
import { Cookie, Form } from "../../models";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService, CookiesService, FormService, LoadingService, LocalizationService } from "../../services";
import { NavigationService } from '../../services/navigation.service';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-newsletter-tags-form',
  templateUrl: './newsletter-tags-form.component.html',
  styleUrls: ['./newsletter-tags-form.component.css']
})
export class NewsletterTagsFormComponent implements OnInit, OnDestroy {

  formId: string;
  form: Form;
  formMessage: string;
  sended: boolean = false;
  recapcha: boolean = false;
  recapchaValid: boolean = false;
  recapchaSiteKey: string = '';
  cookieConfig: Cookie;
  cookieAccepted: boolean = false;
  httpRequesSended: boolean = false;
  firstFormFieldDiv: any;
  firstFormField: any;
  emailFormField: any;
  email: any;

  //  Properties for notification
  showNotification: boolean = false;
  notificationMessage: string = '';
  private notificationTimeoutSubscription: Subscription = new Subscription();

  /**
   * GenericComponent constructor
   * @param route
   * @param formService
   * @param commonService
   * @param cookieService
   * @param loadingService
   * @param localization
   */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formService: FormService,
    private commonService: CommonService,
    private cookieService: CookiesService,
    private loadingService: LoadingService,
    private localization: LocalizationService,
    private navigation: NavigationService
  ) {
  }

  /**
   * ng on init function
   */
  ngOnInit(): void {
    this.localization.lang.subscribe(lang => this.getNewsletterTagsForm());
    this.cookieService.cookieAccepted.subscribe((cookie) => {
      this.cookieAccepted = this.cookieService.isRecaptchaAccepted();
      this.getCookiesConfig();
    });
  }

  /**
   * Get contact form fields
   */
  getNewsletterTagsForm(): void {
    this.formService.getForm('newsletter-tags-form')
      .subscribe((form: Form) => {
        this.route.queryParams.subscribe(params => {
          this.email = params.email;
        });
        this.loadingService.stopLoading();
        this.form = form;
        this.formMessage = form.config.confirmation_message;
        this.form.fields.sort(function (a: any, b: any) {
          return a.weight - b.weight;
        });

        this.commonService.setStaticAliases("newsletter-tags");
        setTimeout(() => {
          this.getFormEmail()
        }, 1000);
      });
  }

  /**
   * get cookie config
   */
  getCookiesConfig(): void {
    this.cookieService.getCookiesConfig()
      .subscribe((cookie: Cookie) => {
        this.cookieConfig = cookie;
        this.recapcha = this.cookieService.isRecaptchaAccepted();
        this.recapchaSiteKey = cookie.cookie_re_captcha_site_key;
      });
  }

  /**
   * Submit form
   */
  submit(form) {
    this.loadingService.startLoading();
    let isValid: boolean = true;
    let data = form.value;


    Object.keys(data).forEach(key => {
      document.getElementById(key).classList.remove('error');
      let value = data[key];

      if (key == "field_newsletter_email") {
        data['field_newsletter_email'] = document.getElementById(key).getAttribute('ng-value');
      }

      if (key == 'field_select_all_tags') {
        var checkbox = document.getElementById(key) as HTMLInputElement | null;
        checkbox.checked == true ? value = 'true' : value = 'false';
        // Force value to be readable and selected for content creation and mail sending
        data[key] = value;
      }

      if (key.includes('option_tag_')) {
        var checkbox = document.getElementById(key) as HTMLInputElement | null;
        var select_all_tags_checkbox = document.getElementById('field_select_all_tags') as HTMLInputElement | null;
        select_all_tags_checkbox.checked == true || checkbox.checked == true ? value = 'true' : value = 'false';
        data[key] = value;
      }

      if (key == 'field_privacy_disclamer' && !value) {
        document.getElementById(key).parentElement.classList.add('error');
        isValid = false;
      } /*  JMJ. Email is set auto. No need to check it. else if (document.getElementById(key).hasAttribute('required') && (!value || value == 'null')) {
        document.getElementById(key).classList.add('error');
        isValid = false;
      }*/
    });

    if (!this.cookieAccepted || (this.recapcha && !this.recapchaValid)) {
      isValid = false;
    }

    if (isValid) {
      data['recipients'] = this.form.config.recipients.join(',');
      this.formService.getFormData('newsletter-tags-form', data)
        .subscribe((resp: any) => {
          this.loadingService.stopLoading();
          this.sended = form.submitted;

          /** Redirect user to last page before subscribing */
          this.back();
        });
        // Handle success
        this.showNotification = true;
        this.notificationMessage = this.formMessage;
        this.startNotificationTimeout();
    } else {
      this.loadingService.stopLoading();

      // Handle error
      this.showNotification = true;
      this.notificationMessage = '<b>An error has occured.</b><br>\n' + '<b>Please, try again.</b>';
      this.startNotificationTimeout();
    }
  }

  private startNotificationTimeout() {
    this.notificationTimeoutSubscription.unsubscribe(); // Clear previous subscription if any
    this.notificationTimeoutSubscription = timer(15000).subscribe(() => {
      this.showNotification = false;
    });
  }

  /**
   * resolved re-captcha
   *
   * @param captchaResponse
   */
  resolved(captchaResponse: string) {
    this.recapchaValid = true;
  }

  /**
   * cancel submit
   */
  cancel(): void {
  }

  /**
   * hide cookie modal
   */
  accept(): boolean {
    this.cookieService.setCookie(this.cookieService.cookieName, "yes");
    this.cookieService.acceptCookie(true);
    return false;
  }

  /**
   * Listen the checkbox "All tags" to select all the checkboxes
   */
  selectAllCheckboxes(e) {
    var parentDiv = e.target.parentElement.parentElement.parentElement
    var tagsDiv = parentDiv.nextElementSibling;
    var tagsElements = tagsDiv.querySelectorAll('label');

    if (e.target.checked) {
      tagsElements.forEach(function (tag) {
        tag.querySelector('input').checked = true;
      });
    } else {
      tagsElements.forEach(function (tag) {
        tag.querySelector('input').checked = false;
      });
    }
  }

  /**
   * Listen all the checkboxes to uncheck "All tags" checkbox
   */
  unselectCheckbox(e) {
    var tagsDiv = e.target.parentElement.parentElement.parentElement
    var parentDiv = tagsDiv.previousElementSibling;
    var checkboxElement = parentDiv.firstElementChild.firstElementChild.firstElementChild;

    if (e.target.checked == false) {
      checkboxElement.checked = false;
    }
  }

  /**
  * Inject email form newsletter form in a field
  */
  getFormEmail() {
    this.emailFormField = document.querySelector('#field_newsletter_email');
    this.emailFormField.value = this.email;
    this.emailFormField.setAttribute('ng-value', this.email);
  }

  /** Newsletter form submission routing */
  back(): void {
    this.navigation.back()
  }

  ngOnDestroy(): void {
    // Cleanup
    this.notificationTimeoutSubscription.unsubscribe();
  }

}
