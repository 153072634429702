<section class="generic-header" [style.background-image]="'url(' + form?.config?.image + ')'">
  <div class="text-container">
    <h1 class="title"> {{ form?.config?.title }} </h1>
    <div class="content">
      {{ form?.config?.subtitle }}
    </div>
  </div>
</section>

<section class="generic-breadcrumb" id="generic-section">
  <div class="row">
    <div class="col-sm-12">
      <a href="/"><img src="../assets/img/icon/home.png" alt="home"/></a> |
      <span> {{ form?.config?.title }} </span>
    </div>
  </div>
  <div class="sharing_container">
    <dl class="share">
      <dt class="toggle_share">
        <button class="share_toggle" title="Share icon">
          <img src="../assets/img/icon/Share_default.svg" alt="share icon"/>
        </button>
      </dt>
      <dd class="toggle_pane">
        <ul class="share_list">
          <li class="share_item">
            <a href="https://www.facebook.com/sharer/sharer.php?u={{ getPageUrl() }}&quote={{ form?.config?.title }}" title="share on Facebook" target="_blank" class="share_link">
              <svg class="icon" width="65" height="65" viewBox="0 0 65 65" xmlns="http://www.w3.org/2000/svg">
                <path d="M37 27.375H34.8366C34.1436 27.375 33.625 27.6585 33.625 28.3751V29.625H37L36.7311 33H33.625V42H30.25V33H28V29.625H30.25V27.4616C30.25 25.1869 31.447 24 34.1436 24H37V27.375Z"/>
              </svg>
            </a>
          </li>
          <li class="share_item">
            <a href="https://twitter.com/intent/tweet?text={{ form?.config?.title }}&url={{ getPageUrl() }}" title="share on Twitter" target="_blank" class="share_link">
              <svg class="icon" width="65" height="65" viewBox="0 0 65 65" xmlns="http://www.w3.org/2000/svg">
                <path d="m38.42551,23.16087l3.16114,0l-6.90451,7.91185l8.12311,10.7664l-6.35963,0l-4.9813,-6.53095l-5.70009,6.53095l-3.16242,0l7.385,-8.46318l-7.79205,-10.21507l6.52193,0l4.5021,5.96802l5.20801,-5.96802l-0.00129,0zm-1.10781,16.78209l1.75189,0l-11.30614,-14.98511l-1.87813,0l11.43238,14.98511z"></path>
              </svg>
            </a>
          </li>
          <li class="share_item">
            <a href="https://www.linkedin.com/shareArticle?mini=true&url={{ getPageUrl() }}" title="share on LinkedIn" target="_blank" class="share_link_lk">
              <svg class="icon" width="65" height="65" viewBox="0 0 65 65" xmlns="http://www.w3.org/2000/svg">
                <path d="M27.0492 42H23.3115V28.7688H27.0492V42ZM25.1803 27.2436C23.9768 27.2436 23 26.2934 23 25.1218C23 23.9502 23.9768 23 25.1803 23C26.3839 23 27.3607 23.9502 27.3607 25.1218C27.3607 26.2934 26.3851 27.2436 25.1803 27.2436ZM42 42H38.2623V35.2593C38.2623 31.2082 33.2787 31.5149 33.2787 35.2593V42H29.541V28.7688H33.2787V30.8918C35.0192 27.7813 42 27.5515 42 33.87V42Z"/>
              </svg>
            </a>
          </li>
        </ul>
      </dd>
    </dl>
  </div>
</section>

<section class="generic-content pb-5" *ngIf="form && !sended">
  <div class="row">
    <div class="col-md-12 col-lg-8 col-sm-12 content" [innerHTML]="form?.config?.content | safeHtml">
    </div>

    <div class="col-lg-4 col-md-12 col-sm-12 pl-md-5 Form_92">
      <h3 class="pt-3 text-center text-muted"> {{ form?.config?.form_title }} </h3>
      <form class="form-contact" name="contactForm" #contactForm="ngForm" (ngSubmit)="submit(contactForm)">
        <div *ngFor="let field of form?.fields" [ngSwitch]="field.type" class="col-sm-12">
          <div class="form-group" *ngSwitchCase="'textfield'">
            <input type="text" class="form-control" [name]="field.id" [id]="field.id" [required]="field.required"
                   [placeholder]="field.required ? field.title + '*' : field.title" ngModel/>
          </div>

          <div class="form-group" *ngSwitchCase="'email'">
            <input type="text" class="form-control" [name]="field.id" [id]="field.id" [required]="field.required"
              [placeholder]="field.required ? field.title + '*' : field.title" ngModel />
          </div>

          <div class="form-group" *ngSwitchCase="'textarea'">
            <textarea class="form-control" [name]="field.id" [id]="field.id" [required]="field.required"
                      [placeholder]="field.title" style="height: 310px" ngModel></textarea>
          </div>

          <div class="form-group" *ngSwitchCase="'select'">
            <select class="form-control amc-select" [name]="field.id" [id]="field.id" [required]="field.required" [ngModel]="field.options[0].id">
              <option [value]="" [selected]="true">
                {{ field.title }}
              </option>
              <option *ngFor="let option of field.options" [value]="option.id">
                {{ option.value }}
              </option>
            </select>
          </div>

          <div class="form-group" *ngSwitchCase="'checkbox'">
            <label *ngIf="field.id != 'field_select_all_tags'" class="checkbox-container">
              {{ field.title }}
              <input type="checkbox" [name]="field.id" [id]="field.id" [required]="field.required" ngModel>
              <span class="checkmark"></span>
            </label>

            <label *ngIf="field.id == 'field_select_all_tags'" class="checkbox-container">
              {{ field.title }}
              <input type="checkbox" [name]="field.id" [id]="field.id" [required]="field.required" ngModel (change)="selectAllCheckboxes($event)">
              <span class="checkmark"></span>
            </label>
          </div>

          <div class="form-group" *ngSwitchCase="'checkboxes'">
              <label class="checkbox-container" *ngFor="let option of field.options" [value]="option.id">
                {{ option.value }}
                <input type="checkbox" [name]="option.id" [id]="option.id" ngModel (change)="unselectCheckbox($event)">
                <span class="checkmark"></span>
              </label>
          </div>
        </div>

        <div class="col-sm-12 mb-3" *ngIf="cookieAccepted && recapcha">
          <re-captcha class="captcha_class" (resolved)="resolved($event)" siteKey="{{ recapchaSiteKey }}"></re-captcha>
        </div>

        <div class="contact_btn col-sm-12 mb-3" *ngIf="!cookieAccepted">
          <div class="text-white" [innerHTML]="cookieConfig?.cookie_form_text?.value"></div>
          <button type="button" class="form_btn amc-btn-light" (click)="accept()">
            {{ cookieConfig?.cookie_accept_text }} Cookies
          </button>
        </div>

        <div class="contact_btn col-sm-12">
          <button type="submit" class="amc-btn-light-contact d-flex">
            {{ form?.config?.submit_text }}
            <img src="/assets/img/icon/arrow_cta.png" class="white" alt="Arrow CTA">
          </button>
        </div>
      </form>
    </div>
  </div>
</section>

<section class="generic-content" *ngIf="sended">
  <div class="row">
    <div class="col-sm-12 content" [innerHTML]="form?.config?.confirmation_message">
    </div>
  </div>
</section>
