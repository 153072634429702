<section class="generic-header" [style.background-image]="'url(' + form?.config?.image?.url + ')'">
  <div class="text-container">
    <h1 class="title"> {{ form?.config?.title }} </h1>
    <div class="content">
      {{ form?.config?.subtitle }}
    </div>
  </div>
</section>

<section class="generic-breadcrumb" id="generic-section">
  <div class="row">
    <div class="col-sm-12">
      <a href="/"><img src="../assets/img/icon/home.png" alt="home" /></a> |
      <span> {{ form?.config?.title }} </span>
    </div>
  </div>
</section>

<section class="generic-content" *ngIf="form?.fields && !sended">
  <form class="form-newsletter-tags form-contact" name="newsletterTagsForm" #newsletterTagsForm="ngForm" (ngSubmit)="submit(newsletterTagsForm)">
    <div class="row">
      <div *ngFor="let field of form?.fields; let i = index" [ngSwitch]="field.type" class="col-sm-12 offset-sm-0"
        [class.offset-md-2]="![6,8,10].includes(i)"
        [class.col-md-8]="i < 5 || field.type == 'textarea' || field.type == 'checkbox'"
        [class.col-md-4]="i >= 5 && field.type != 'textarea' && field.type != 'checkbox'">

        <div class="form-group" *ngSwitchCase="'textfield'">
          <input type="text" class="form-control" [name]="field.id" [id]="field.id" [required]="field.required"
            [placeholder]="field.required ? field.title + '*' : field.title" ngModel />
        </div>

        <div class="form-group" *ngSwitchCase="'email'">
          <input type="text" class="form-control" [name]="field.id" [id]="field.id" [required]="field.required"
            [placeholder]="field.required ? field.title + '*' : field.title" [(ngModel)]="form.contact.email"/>
        </div>

        <div class="form-group" *ngSwitchCase="'textarea'">
          <textarea class="form-control" [name]="field.id" [id]="field.id" [required]="field.required"
            [placeholder]="field.title" style="height: 310px" ngModel></textarea>
        </div>

        <div class="form-group" *ngSwitchCase="'select'">
          <label class="text-white" *ngIf="'field_recipient' == field.id"> {{ field.title }} </label>
          <select class="form-control amc-select" [name]="field.id" [id]="field.id" [required]="field.required"
            [ngModel]="field.options[0].id">
            <option *ngFor="let option of field.options" [value]="option.id" [selected]="option.value == 'null'">
              {{ option.value }}
            </option>
          </select>
        </div>

        <div class="form-group" *ngSwitchCase="'checkbox'">
          <label *ngIf="field.id != 'field_select_all_tags'" class="checkbox-container">
            {{ field.title }}
            <input type="checkbox" [name]="field.id" [id]="field.id" [required]="field.required" ngModel>
            <span class="checkmark"></span>
          </label>

          <label *ngIf="field.id == 'field_select_all_tags'" class="checkbox-container">
            {{ field.title }}
            <input type="checkbox" [name]="field.id" [id]="field.id" [required]="field.required" ngModel
              (change)="selectAllCheckboxes($event)">
            <span class="checkmark"></span>
          </label>
        </div>

        <div class="form-group" *ngSwitchCase="'checkboxes'">
          <label class="checkbox-container" *ngFor="let option of field.options" [value]="option.id">
            {{ option.value }}
            <input type="checkbox" [name]="option.id" [id]="option.id" [(ngModel)]="option.checked" (change)="unselectCheckbox($event)">
            <span class="checkmark"></span>
          </label>
        </div>
      </div>

      <div class="col-md-8 offset-md-2 col-sm-12 offset-sm-0 mb-3" *ngIf="cookieAccepted && recapcha">
        <re-captcha (resolved)="resolved($event)" siteKey="{{ recapchaSiteKey }}"></re-captcha>
      </div>

      <!--
      <div class="contact_btn col-md-8 offset-md-2 col-sm-12 offset-sm-0 mb-3" *ngIf="!cookieAccepted">
        <div class="text-white" [innerHTML]="cookieConfig?.cookie_form_text?.value"></div>
        <button type="button" class="amc-btn-light" (click)="accept()">
          {{ cookieConfig?.cookie_accept_text }} Cookies
        </button>
      </div>
      -->

      <div class="contact_btn submit_btn_div col-md-8 offset-md-2 col-sm-12 offset-sm-0">
        <button type="submit" class="amc-btn-light-contact">
          {{ form?.config?.submit_text }}
          <img src="/assets/img/icon/arrow_cta.png" class="white" alt="Arrow CTA">
        </button>
      </div>
    </div>
  </form>
</section>

<!-- Notification Message -->
<div *ngIf="showNotification" class="notification-container">
  <div class="alert-message" [innerHTML]="notificationMessage | safeHtml"></div>
</div>

<section class="generic-content" *ngIf="sended">
  <div class="row">
    <div class="col-sm-12 content" [innerHTML]="form?.config?.confirmation_message">
    </div>
  </div>
</section>
