<section class="home_slider_section">
  <div *ngIf="slider && !slider.is_video" class="home_slider_container">
    <ngx-slick-carousel
      [config]="slideConfig">
      <div ngxSlickItem *ngFor="let image of slider.images;let i = index;" class="home_slider">
        <img src="{{ image.url }}" width="100%" alt="{{ image.title }}">
        <h1> {{ image.title }} </h1>
        <span (click)="scroll('personae')"> {{ slider.scroll_down_text }} </span>
        <span *ngIf="slider.caption !== null" class="row col-sm-12 home_slider_caption">{{ slider.caption[i] }}</span>
      </div>
    </ngx-slick-carousel>
  </div>

  <div *ngIf="slider?.is_video" class="home_slider ratio ratio-16x9">
    <video [src] = "slider.url_video" autoplay muted loop oncanplay="this.play()" onloadedmetadata="this.muted = true" class="home_video">
      Your browser does not support HTML5 video.
    </video>

    <span (click)="scroll('personae')"> {{ slider.scroll_down_text }} </span>
  </div>
</section>

