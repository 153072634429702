import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { CommonService } from "../../../services";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit, AfterViewInit {

  @Input() content: any;

  constructor (
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.expandAllPanels();
  }

  /**
   * scroll to response
   *
   * @param id
   */
  scrollToResponse(event: Event, id: number) {
    event.preventDefault(); // Empêche la navigation par défaut du lien

    const collapse = document.getElementById("collapse-" + id);
    const link = document.getElementById("link-" + id); // Récupère le lien pour modifier aria-expanded
    const position = document.getElementById("position-" + id);

    if (!collapse || !link) return; // Sécurité pour éviter les erreurs si l'élément n'existe pas

    // Basculer la classe 'show' pour afficher ou masquer le contenu
    const isExpanded = collapse.classList.contains('show');

    if (isExpanded) {
      collapse.classList.remove('show'); // Fermer le panneau
      link.setAttribute("aria-expanded", "false"); // Modifier l'état du loquet
    } else {
      collapse.classList.add('show'); // Ouvrir le panneau
      link.setAttribute("aria-expanded", "true"); // Modifier l'état du loquet
    }

    // Ajouter un délai pour synchroniser le scroll avec l'animation CSS
    setTimeout(() => {
      if (collapse.classList.contains('show')) {
        position?.classList.remove("h-auto");
        position?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 500); // Délai ajustable en fonction de l'animation
  }



  /**
   * got to url
   *
   * @param route
   */
  goTo(route: any) {
    console.log(`Go to route: ${route}`);
    return this.commonService.goToUrl(route);
  }

  /**
   * Expand all panels initially
   */
  expandAllPanels(): void {
    if (this.content && Array.isArray(this.content.questions)) {
      this.content.questions.forEach((_, index) => {
        const collapse = document.getElementById(`collapse-${index}`);
        collapse.classList.add('show');
      });
    }
  }
}
