import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CanActivateFn } from '@angular/router';

export const languageGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  const validLanguages: string[] = ['fr', 'en', 'de', 'es', 'pl'];
  const router = inject(Router);

  const langCode = route.params['lang'];

  if (validLanguages.includes(langCode)) {
    return true;
  } else {
    router.navigate(['/en']); // Redirect to default language
    return false;
  }
};

