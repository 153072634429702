import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CookiesService, LocalizationService} from "../../../services";
import {Cookie} from "../../../models";

type ConsentCheck = (serviceKey: string) => boolean;

declare var tarteaucitron: any;
declare var tarteaucitronForceLanguage: any;
declare var tarteaucitronIsLoaded: any;

@Component({
  selector: 'app-cookies',
  styleUrls: ['./cookies.component.css'],
  template: `
    <div id="tarteaucitron-container"></div>
  `,
})
export class CookiesComponent implements OnInit {

  cookieConfig: Cookie;
  tacExisting: boolean;
  matomoAuthorized: boolean = false;
  matomoScript: string;

  constructor(
    private cookieService: CookiesService,
    private localization: LocalizationService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.cookieService.cookieAccepted.subscribe((cookie) => {
      console.log('cookie received from back.');
      let cookieAccepted = this.cookieService.getCookie(this.cookieService.cookieName);
    });

    this.localization.lang.subscribe((lang) => {
      if(lang.length > 0) {
        this.getCookiesConfig()
      }

      // TAC implementation
      //console.log (`Received from back SiteKey: ${this.cookieConfig.cookie_re_captcha_site_key}`);
      tarteaucitron.user.recaptchaSiteKey = '6LcqclAdAAAAADjDp7epgDeYk9-HWitW5Mze7xIV' ; //this.cookieConfig.cookie_re_captcha_site_key;
      this.initializeTarteaucitron(lang);

    });

    window.addEventListener('tac.event_all_service', () => {
      console.log("TAC All services detected.");
      this.updateConsentStatus();
    });

    // Listen to TAC service acceptation changes
    window.addEventListener('tac.accepted', () => {
      console.log("TAC Accepted detected.");
      this.updateConsentStatus();
    });

    window.addEventListener('tac.denied', () => {
      console.log("TAC Denied detected.");
      this.updateConsentStatus();
    });
  }

  /**
   * setup specific js for tarteaucitron handling
   */
  private initializeTarteaucitron(lang: string): void {

    //  No language specified. Don't load TAC.
    if(lang === "") {
      return;
    }
    console.log(`tarteaucitron - detected language: '${lang}'`);

    // Remove tarteaucitron if existing
    const tarteaucitronDiv = document.getElementById('tarteaucitronRoot');
    if (tarteaucitronDiv) {
      console.log(`tarteaucitron - Reloading banniere for Lang: '${lang}'`);
      this.deleteCookie('tarteaucitron')
      tarteaucitron.reloadThePage = true;
    } else {
      console.log(`tarteaucitron - Banner not yet existing for Lang: '${lang}'`);
    }

    this.loadTarteaucitronLanguageScript(lang).then(() => {
      console.log(`tarteaucitron - Language script for '${lang}' loaded successfully.`);

      /**
       *  Add Matomo service
       **/
      tarteaucitron.services.matomo = {
        "key": "matomo",
        "type": "analytic",
        "name": "Matomo",
        "uri": "https://matomo.org",
        "needConsent": true,
        "cookies": ['_pk_ref', '_pk_cvar', '_pk_id', '_pk_ses'],
        "js": () => {
          //document.head.append(document.createRange().createContextualFragment(this.cookieConfig.cookie_matomo_script));
        }
      };

      /**
       *  Add google receptcha service
       **/
      tarteaucitron.services.recaptcha = {
        key: "recaptcha", // Identifiant unique du service
        type: "api", // Type du service (api, analytics, social, etc.)
        name: "Google reCAPTCHA", // Nom affiché dans la liste des cookies
        uri: "https://policies.google.com/privacy", // Lien vers la politique de confidentialité
        needConsent: true, // Nécessite un consentement
        cookies: ['_GRECAPTCHA'], // Liste des cookies utilisés
        js: function () {
          if (tarteaucitron.user.recaptchaSiteKey) {
            // Charge le script Google reCAPTCHA avec la clé utilisateur
            //tarteaucitron.addScript(
            //  `https://www.google.com/recaptcha/api.js?render=${tarteaucitron.user.recaptchaSiteKey}`
            //);
          } else {
            console.error("Google reCAPTCHA site key is not defined in tarteaucitron.user.recaptchaSiteKey");
          }
        },
        fallback: function () {
          // Code alternatif si le service est bloqué (exemple : afficher un message ou désactiver un formulaire)
          document.querySelectorAll('.g-recaptcha').forEach(function (element) {
            element.innerHTML = "Google reCAPTCHA is disabled.";

          });
        },
      };

      //  Force tarteaucitron language
      tarteaucitronForceLanguage = lang;

      /**
       *  Initialize tarteaucitron
       **/
      console.log(`tarteaucitron - Running Init`);
      tarteaucitron.init({
        "privacyUrl": `${lang}/generic/privacy-policy`, // URL de la politique de confidentialité
        "hashtag": "#tarteaucitron", // Identifiant du hashtag pour le consentement
        "cookieName": "tarteaucitron", // Nom du cookie de consentement
        "orientation": "bottom", // Position de la bannière
        "showAlertSmall": false, // Alerte réduite dans le coin
        "cookieslist": false, // Afficher la liste des cookies
        "customCss": "/assets/tarteaucitron/tarteaucitron.css",
        "customJs": "/assets/tarteaucitron/tarteaucitron.js",
        "serviceJs": "/assets/tarteaucitron/tarteaucitron.services.js",
        "adblocker": false,
        "AcceptAllCta": true, // Bouton "Tout accepter"
        "highPrivacy": true, // Nécessite l'interaction de l'utilisateur pour accepter
        "handleBrowserDNTRequest": false, // Désactiver le suivi Do Not Track
        "removeCredit": true, // Retirer le lien Tarteaucitron
        "moreInfoLink": true, // Lien vers plus d'informations
        "useExternalCss": true, // Utilisation d'un CSS externe
        "useExternalJs": true, // Utilisation d'un CSS externe
        "bodyPosition": "bottom", /* or top to bring it as first element for accessibility */
        "groupServices": false, /* Group services by category */
        "showDetailsOnClick": true, /* Click to expand the description */
        /*"serviceDefaultState": "wait", /* Default state (true - wait - false) */
        "closePopup": false, /* Show a close X on the banner */
        "showIcon": true, /* Show cookie icon to manage cookies */
        /*"iconSrc": "<?= get_stylesheet_directory_uri() ?>/tarteaucitron/cookie-bite-white.svg", /* Optionnal: URL or base64 encoded image */
        "iconPosition": "BottomLeft", /* BottomRight, BottomLeft, TopRight and TopLeft */
        "DenyAllCta": true,	/* Show the deny all button */
        "alwaysNeedConsent": false,	/* Ask the consent for "Privacy by design" services */
        //"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for multisite */
        "readmoreLink": "",	/* Change the default readmore link */
        "mandatory": true, /* Show a message about mandatory cookies */
        "mandatoryCta": true, /* Show the disabled accept button when mandatory on */
        //"customCloserId": "", /* Optional a11y: Custom element ID used to open the panel */

      });




      // Remove services if required
      tarteaucitron.job = (tarteaucitron.job || []).filter(job => job !== 'recaptcha' && job !== 'matomo');

      // Add services
      tarteaucitron.job.push('recaptcha');
      tarteaucitron.job.push('matomo');


    });
  }

  /**
   * Charger le script de langue pour Tarteaucitron.
   */
  private loadTarteaucitronLanguageScript(lang: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const existingScript = document.querySelector(`script[src*="tarteaucitron.${lang}.js"]`);
      if (existingScript) {
        existingScript.remove(); // Remove existing script
      }

      const script = document.createElement('script');
      script.src = `/assets/tarteaucitron/tarteaucitron.${lang}.js`; // Adaptez le chemin si nécessaire
      script.type = 'text/javascript';
      script.async = true;

      script.onload = () => resolve();
      script.onerror = () => reject(`Failed to load script: ${script.src}`);

      document.head.appendChild(script);
    });
  }

  private destroyTarteaucitron(): void {
    // Example: Clear the Tarteaucitron instance or reset its state
    console.log('Destroying Tarteaucitron...');
    if ((window as any).tarteaucitron && (window as any).tarteaucitron.user) {
      (window as any).tarteaucitron.user = {}; // Clear the user settings
    }
    document.getElementById('tarteaucitron-container')!.innerHTML = ''; // Clear the container
  }

  private reloadTarteaucitron(language: string): void {
    console.log(`Reloading Tarteaucitron for language: ${language}`);
    this.destroyTarteaucitron(); // Clear the existing instance
    this.initializeTarteaucitron(language); // Reinitialize with the new language
  }


  private updateConsentStatus(): void {
    const isServiceAccepted: ConsentCheck = (serviceKey: string) => {
      return tarteaucitron.state[serviceKey] === true;
    };

    this.cookieService.setMatomoAccepted(isServiceAccepted('matomo'));
    console.log(`MatomoAccepted set to: ${this.cookieService.isMatomoAccepted()}` );

    this.cookieService.setRecaptchaAccepted(isServiceAccepted('recaptcha'));
    console.log(`RecaptchaAccepted set to: ${this.cookieService.isRecaptchaAccepted()}` );

    if(this.cookieService.isMatomoAccepted()) {
      this.injectMatomoScript();
    }
  }

  /**
   * Get cookies config
   */
  getCookiesConfig(): void {
    this.cookieService.getCookiesConfig()
      .subscribe((cookie: Cookie) => {
        this.cookieConfig = cookie;
        this.matomoScript = this.cookieConfig.cookie_matomo_script;
        console.log('Matomo script received');

        //if ( this.cookieConfig.cookie_matomo == 1 && this.cookieConfig.cookie_matomo_script.length > 0 ) {
        //  document.head.append(document.createRange().createContextualFragment(this.cookieConfig.cookie_matomo_script));
        //}
      });
  }

  injectMatomoScript(): void {
    console.log('Matomo script injected');
    document.head.append(document.createRange().createContextualFragment(this.matomoScript));
  }

  private deleteCookie(cookieName: string): void {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  /**
   *  Get selected language from utl for tarteaucitron loading
   *  **/
  private getLanguageFromUrl(): string {
    const supportedLanguages: string[] = ['fr', 'en', 'de', 'es', 'pl'];
    const defaultLanguage: string = 'en';
    const urlSegments = this.router.url.split('/');
    const langFromUrl = urlSegments[1];
    return supportedLanguages.includes(langFromUrl) ? langFromUrl : defaultLanguage;
  }

  /**
   * Extracts the language code from the current URL.
   * Assumes the format: "https://domain/language-code/rest-of-path"
   * @returns The language code as a string or null if not found.
   */
  private getLanguageCodeFromURL(): string | null {
    // Get the current URL from the window object
    const currentURL = window.location.href;

    // Define a regex pattern to match the language code part of the URL
    const languageCodeRegex = /https?:\/\/[^\/]+\/(\w{2})(?:\/|$)/;

    // Execute the regex on the URL
    const match = currentURL.match(languageCodeRegex);

    // If a match is found, return the captured group (language code)
    return match ? match[1] : null; // Return null if no match is found
  }

}
